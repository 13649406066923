
@media screen and (max-width: 768px) {
  .upload-btn {
    .btn {
      font-size: 1.5em;
    }
  }

  .invalid-message,
  tbody tr td,
  thead th {
    font-size: 1.5em;
  }
}

