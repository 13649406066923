@import "vars";

.main {

  &__table {
    padding : 20px;
    position: relative;

    .table {
      margin-top: 100px;
    }
  }

  .upload-btn {
    position: absolute;
    top:50px;
    right : 80px;
  }
}

.table {
  border-collapse: collapse;
  width : 100%;

  thead tr th {
    background-color: #CFE2F3;

    &:not(:first-of-type){
      font-weight: 400;
    }
  }

  tbody tr td, thead th {
    border: 1px solid black;
    padding : 8px 12px;
    text-align: center;
  }

  .error {
    background-color: $error-color;
  }

}

.invalid-message {
  max-width: 400px;
  height : 50px;
  line-height: 50px;
  margin: 0 auto;
  border : 1px solid #7B383E;
  background-color: $error-color;
  text-align: center;
}

.upload-btn {

  input[type='file'] {
    display: none;
  }

  .btn {
    background-color: #92C47D;
    cursor: pointer;
    border : 1px solid #6B8F5E;
    padding: 8px 25px;
    color : #fff;
    display: inline-block;
  }
}

